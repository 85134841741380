import { RouteComponentProps } from '@reach/router';
import { classdFn } from 'classd';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect } from 'react';
import { useUID } from 'react-uid';
import styled from 'styled-components';
import { useMediaLayout } from 'use-media';

import { Paths } from '../paths';
import { TimelineCard } from '../styles/common';
import { ViewportSize } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';
import { Card } from './Card';
import { HeroContainer } from './HeroContainer';
import { Quotes } from './Quotes';
import { SampleCase } from './SampleCase';
import { Step, Timeline } from './Timeline';
import { Typewriter } from './Typewriter';
import { H1, H3, H4, Typography } from './Typography';

const StyledLink = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  color: ${Colors.Blue500};
`;

export function DefaultContent({
  location,
}: {
  location?: RouteComponentProps['location'];
}): JSX.Element {
  const id = useUID();
  const isWide = useMediaLayout({ minWidth: ViewportSize.laptop });
  const staticData = useStaticQuery(graphql`
    query {
      timelineStep1: file(relativePath: { eq: "timeline-step-1.png" }) {
        childImageSharp {
          fixed(width: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep2: file(relativePath: { eq: "timeline-step-2.png" }) {
        childImageSharp {
          fixed(width: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      timelineStep3: file(relativePath: { eq: "timeline-step-3.png" }) {
        childImageSharp {
          fixed(width: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined' && location?.hash.substring(1) === 'how-it-works') {
      const scrollToDiv = document.getElementById(id);
      scrollToDiv?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <HeroContainer>
      <Card
        hero
        className={classdFn('flex flex-col lg:flex-row mb-20 ml-auto mr-auto', { wide: isWide })}>
        <div className="flex-1 text-center lg:text-left pt-4 md:pt-8 lg:pt-16 pl-4 md:pl-10 lg:pl-24 pr-4 md:pr-10 lg:pr-0 order-last lg:order-first">
          <H1 color={Colors.Purple300} className="mb-4 lg:mb-16" style={{ fontWeight: 300 }}>
            Streamline Hyperlink Creation by Letting HyperCite<sup>&reg;</sup> do the Work
          </H1>
          <H4 className="mb-2">Made for Attorneys And The Court</H4>
          <Typography
            className="mb-4 lg:mb-16"
            style={{ fontSize: 16, lineHeight: '26px', fontWeight: 300 }}>
            HyperCite<sup>&reg;</sup> autonomously transforms a brief, motion, or other appellate
            court document&apos;s citations to the Record on Appeal and items filed in the Court
            of Appeals into hyperlinks. All HyperCite
            <sup>&reg;</sup> requires to achieve this result is the parent document (breif, motion,
            or etc.) and the case number.
          </Typography>
          <H4 className="mb-2">Save time</H4>
          <Typography
            className="mb-10 lg:mb-16"
            style={{ fontSize: 16, lineHeight: '26px', fontWeight: 300 }}>
            Hyperlink citations quickly with automation to comply with the court&apos;s hyperlinking
            requirement without the stress and time of manual linking, and even hyperlink your oral
            argument notes.
          </Typography>
        </div>

        <div
          className="flex-0 flex flex-row justify-center pt-4 md:pt-8 lg:pt-0 pl-4 md:pl-8 lg:pl-0 pr-4 md:pr-8 lg:pr-0 order-first lg:order-last"
          style={{ minWidth: isWide ? 535 : 'auto' }}>
          <SampleCase />
        </div>
      </Card>

      <div className="flex flex-col ml-auto mr-auto max-w-screen-xl" id={id}>
        <H4 className="mb-12 text-center lg:text-left" style={{ fontSize: 20 }}>
          How it Works
        </H4>
        <Timeline className="mb-16">
          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-0 lg:mr-20">
                <H3>Write it Right</H3>
                <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
                  When writing, cite to the ROA, transcripts, prior briefing, and etc. as the court
                  requests. When you repeat one of those cites, write &ldquo;COA-<i>Id</i>.&rdquo;
                  instead of just <i>&ldquo;Id.&rdquo;</i>
                  Continue to use <i>Id</i>. for repeat citations to items outside the Court&apos;s
                  docket, like cases and statutes. For more information on how to write it right,
                  visit our <StyledLink to={Paths.UserGuide}>User Guide</StyledLink>
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Typewriter />
                </TimelineCard>
              </div>
            </div>
          </Step>

          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-0 lg:mr-20">
                <H3>Document and Case Number</H3>
                <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
                  Once you have finished writing your brief or other parent document, upload a PDF
                  of that document to HyperCite<sup>&reg;</sup>, tell us your case number, and hit
                  process.
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard
                  isWide={isWide}
                  className="px-4 lg:px-0"
                  style={{ backgroundColor: 'rgba(4, 9, 61, 0.75)' }}>
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep2.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>

          <Step>
            <div className="flex flex-col lg:flex-row">
              <div className="flex-0 mr-0 lg:mr-20">
                <H3>Robots, and Then Download</H3>
                <Typography className="font-light" style={{ fontSize: 16, lineHeight: '26px' }}>
                  After submission, our bots should accomplish linking within seconds of processing
                  and deliver the now linked output for download. After download, be sure to check
                  your hyperlinks prior to filing.
                  <br />
                  <br />
                  Happy hyperlinking!
                  <br />
                  <br />- The HyperCite<sup>&reg;</sup> Team
                </Typography>
              </div>
              <div className="flex-1">
                <TimelineCard isWide={isWide} className="px-4 lg:px-0">
                  <Img
                    style={{ maxWidth: '100%' }}
                    className="rounded-sm lg:rounded-none"
                    fixed={staticData.timelineStep3.childImageSharp.fixed}
                  />
                </TimelineCard>
              </div>
            </div>
          </Step>
        </Timeline>
      </div>

      <div className="flex flex-col ml-auto mr-auto max-w-screen-xl">
        <Quotes />
      </div>
    </HeroContainer>
  );
}
